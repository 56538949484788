/* // https://codepen.io/grayghostvisuals/pen/GqzRox?editors=1100 */

/* /// REQUIRED */
/* /// ========================================================== */

$gutter: 20px;
$caret-unit: 25px;
$bubble-bg: white;

@function opposite-direction($directions) {
  $opposite-directions: ();
  $direction-map: (
    'top': 'bottom',
    'right': 'left',
    'bottom': 'top',
    'left': 'right',
    'center': 'center',
    'ltr': 'rtl',
    'rtl': 'ltr'
  );

  @each $direction in $directions {
    $direction: to-lower-case($direction);

    @if map-has-key($direction-map, $direction) {
      $opposite-directions: append($opposite-directions, unquote(map-get($direction-map, $direction)));
    } @else {
      @warn "No opposite direction can be found for `#{$direction}`. Direction omitted.";
    }
  }

  @return $opposite-directions;
}


/// Unit Removal
@function strip-unit($num) {
    @return $num / ($num * 0 + 1);
}


/// Triangle Mixin
/// ==========================================================

@mixin caret($point, $border-width, $color) {
  $opposite: opposite-direction($point);
  border: $border-width solid transparent;
  border-#{$opposite}: $border-width solid $color;
  border-#{$point}: 0;
  height: 0;
  width: 0;
}


/// Component
/// ==========================================================

.speech-bubble {
  filter: drop-shadow(-1px -1px 2px rgba(black, .10)) drop-shadow(1px 2px 2px rgba(black, .15));
  position: relative;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  background: $bubble-bg;
  width: fit-content;
  color: black;

  &::before {
    @include caret(bottom, ($caret-unit / 2), $bubble-bg);
    border-top-width: $caret-unit;
    content: '';
    display: block;
    position: absolute;
    left: 3rem;
    bottom: -$caret-unit;
    transform-origin: center;
    transform: rotate(90deg) skew(-(strip-unit($caret-unit))+deg) translateY($caret-unit / 1.5);
  }
}
